import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from '../store';
import {useAppTranslation} from "../services/i18n";
import {useParams} from "react-router-dom";
import {fetchContentOffer, fetchContentOffers} from "store/offer";
import {isApiResultError} from "../helpers/api";
import {
    ContentInCompanySpaceIndexResponse,
    ContentVersionShowResponse,
    MarketplaceOfferEvaluationComponents,
    MarketplaceOfferIndexResponse,
    MarketplaceOfferShowResponse,
    OfferCreate,
    OfferUpdate,
    type UserMarketplaceProfileShowResponse
} from "generated-api";
import {Grid, LinearProgress} from "@mui/material";
import {fetchContent} from "store/content";
import Button from "@mui/material/Button";
import {OfferToggleDialog} from "components/offer/OfferToggleDialog";
import {OfferEditDialog} from "components/offer/OfferEditDialog";
import {PublicOffer} from "components/offer/PublicOffer";
import {OfferDescriptionDialog} from "components/offer/OfferDescriptionDialog";
import {OfferCategoriesDialog} from "components/offer/OfferCategoriesDialog";
import {OfferPromoVideoDialog} from "components/offer/OfferPromoVideoDialog";
import {fetchMarketplaceProfile} from "store/marketplace";

export interface Offer extends MarketplaceOfferIndexResponse, MarketplaceOfferShowResponse {

}

export interface Content extends ContentInCompanySpaceIndexResponse {
    filledGuarantorIds?: number[]
}

export interface Version extends ContentVersionShowResponse {

}

export interface ComponentType extends MarketplaceOfferEvaluationComponents {

}


const createDefaultPublicOffer = (contentId: number): OfferCreate => ({
    content_id: contentId,
    price: '' as any as number, // ts hell
    discounts: [],
    note: '',
    is_public: true
});

export const PublicOfferPage: React.FC = () => {
    const {contentId} = useParams();

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [editOffer, setEditOffer] = useState<{ mode: 'full' | 'price' | 'events', offer: OfferCreate | OfferUpdate }>();
    const [toggleOffer, setToggleOffer] = useState<Offer>();
    const [editDescription, setEditDescription] = useState<Offer>();
    const [editCategories, setEditCategories] = useState<Offer>();
    const [editPromoVideo, setEditPromoVideo] = useState<Offer>();
    const [offer, setOffer] = useState<Offer>();
    const [content, setContent] = useState<Content>();
    const [isLoading, setIsLoading] = useState(true);

    const handleFetchOffers = useCallback(async () => {
        if (!contentId) {
            return;
        }
        setIsLoading(true);
        const res = await dispatch(fetchContentOffers({contentId: +contentId}));
        let offer: Offer | undefined = undefined;
        let content: Content | undefined = undefined;

        if (!isApiResultError(res)) {
            const offers: Offer[] = res.payload as any;
            const contentRes = await dispatch(fetchContent({id: contentId}));
            if (!isApiResultError(contentRes)) {
                const listOffer = offers.find(o => o.is_public);
                if (listOffer?.id) {
                    const offerRes = await dispatch(fetchContentOffer({
                        id: String(listOffer.id),
                        contentId: +contentId
                    }))
                    if (!isApiResultError(offerRes)) {
                        offer = offerRes.payload as Offer;
                    }
                }
                content = contentRes.payload as Content;
            }
            if (content) {
                content.filledGuarantorIds = [];
                if (content.active_version?.guarantors?.length) {
                    await Promise.all(content.active_version.guarantors.map(async (guarantor) => {
                            const profileRes = await dispatch(fetchMarketplaceProfile({
                                userId: guarantor.id,
                                userId2: String(guarantor.id)
                            }));
                            if (!isApiResultError(profileRes)) {
                                const profile = profileRes.payload as UserMarketplaceProfileShowResponse;
                                if (profile.description && profile.description?.length > 3 && content?.filledGuarantorIds) {
                                    content.filledGuarantorIds.push(guarantor.id);
                                }
                            }
                            return profileRes;
                        }
                    ));
                }
            }
            setOffer(offer);
            setContent(content);
        }
        setIsLoading(false);

    }, [contentId, dispatch]);

    useEffect(() => {
        handleFetchOffers().then();
    }, [dispatch, handleFetchOffers]);

    const render = () => {
        if (!isLoading && !content?.active_version) {
            return <Grid container><Grid item xs={12}>
                <div className={'tw-text-center'}>
                    <span>{t('offer.noActiveContent')}</span>
                </div>
            </Grid>
            </Grid>
        }
        if (offer?.id && content?.id) {
            return <PublicOffer offer={offer} content={content} isLoading={isLoading}
                onPriceEdit={() => setEditOffer({mode: 'price', offer})}
                onEventsEdit={() => setEditOffer({mode: 'events', offer})}
                onToggleStatus={() => setToggleOffer(offer)}
                onDescriptionEdit={() => setEditDescription(offer)}
                onCategoriesEdit={() => setEditCategories(offer)}
                onPromoVideoEdit={() => setEditPromoVideo(offer)}
            />;
        }
        return !isLoading && <Grid container>
            <Grid item xs={12} className={'tw-items-end'}>
                <Button color={'primary'} variant={'contained'} disabled={false}
                    onClick={() => setEditOffer({mode: 'full', offer: createDefaultPublicOffer(content!.id)})}
                    className={'tw-w-auto'}>
                    {t('offer.public.create')}
                </Button>
            </Grid>
            <Grid item xs={12}>
                <div className={'tw-text-center'}>
                    <span>{t('offer.public.empty')}</span>
                </div>
            </Grid>
        </Grid>
    }

    return <div className={'kws-content-margin flex tw-flex tw-flex-col tw-relative'}>
        {isLoading && <LinearProgress className={'tw-absolute tw-left-0 tw-right-0'}/>}
        {render()}
        {!!editOffer && !!content &&
            <OfferEditDialog
                content={content}
                offer={editOffer.offer}
                mode={editOffer.mode}
                onSuccess={handleFetchOffers}
                onClose={() => setEditOffer(undefined)}/>}
        {!!toggleOffer && !!content &&
            <OfferToggleDialog
                content={content}
                offer={toggleOffer}
                onSuccess={handleFetchOffers}
                onClose={() => setToggleOffer(undefined)}/>}
        {!!editDescription && !!content && <OfferDescriptionDialog
            content={content}
            offer={editDescription}
            onSuccess={handleFetchOffers}
            onClose={() => setEditDescription(undefined)}/>}
        {!!editCategories && !!content && <OfferCategoriesDialog
            content={content}
            offer={editCategories}
            onSuccess={handleFetchOffers}
            onClose={() => setEditCategories(undefined)}/>}
        {!!editPromoVideo && !!content && <OfferPromoVideoDialog
            content={content}
            offer={editPromoVideo}
            onSuccess={handleFetchOffers}
            onClose={() => setEditPromoVideo(undefined)}/>}
    </div>;
};
