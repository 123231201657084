import {IconButton, InputAdornment, TextField, Tooltip} from '@mui/material';
import {useField, useFormikContext} from 'formik';
import {FormFieldProps} from '../../model/form';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Clear} from "@mui/icons-material";

interface Props extends FormFieldProps {
    type?: string;
    maxlength?: number;
    formatValue?: (value: any) => any;
    normalizeValue?: (value: any) => any;
    rows?: number;
    minRows?: number;
    maxRows?: number;
    minValue?: any;
    maxValue?: any;
    placeholder?: string;
    helperText?: string;
    suffixText?: string | JSX.Element;
    prefixText?: string | JSX.Element;
    tooltip?: string;
    autoFocus?: boolean;
    clearable?: boolean;
    required?: boolean;
    onFocus?: () => void;
}

const INPUT_DELAY = 500;

interface PropsPlain extends Props {
    currentValue: any,
    showError?: boolean,
    error?: string,
    onEnter?: (value: any) => void,
    autoComplete?: string
    pattern?: { re: string, message?: string };
}


export const TextFormFieldPlain = (props: PropsPlain) => {
    const {
        name, label, type, maxlength,
        onChange, onBlur, clearable, onEnter,
        currentValue, showError, error,
        helperText, prefixText, suffixText, autoComplete, onFocus
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const handleOnChange = useCallback((e: any) => {
        if (onChange) {
            onChange(e.target.value);
        }
    }, [onChange]);

    const handleOnBlur = useCallback((e: any) => {
        if (onBlur) {
            onBlur(e.target.value);
        }
    }, [onBlur]);

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Enter') {
            const newValue = (e.target as any)['value'];
            if (onEnter) {
                onEnter(newValue);
            }
        }
    }, [onEnter]);

    const trapEnter = useCallback((e: any) => {
        if (e.key === 'Enter') {
            handleOnBlur(e);
            e.preventDefault();
        }
    }, [handleOnBlur]);

    const field = <TextField
        className={props.readonly ? 'input-readonly' : ((type === 'date' || type === 'datetime-local') ? 'input-date' : undefined)}
        inputRef={inputRef}
        margin={'dense'}
        fullWidth
        id={name}
        name={name}
        label={label}
        hiddenLabel={!label}
        title={props.tooltip ? undefined : (typeof label == 'string' ? label : props.placeholder)}
        autoFocus={props.autoFocus}
        type={type || 'text'}
        value={currentValue === undefined ? '' : currentValue}
        onChange={handleOnChange}
        onKeyDown={type === 'textarea' ? undefined : (type === 'date' ? trapEnter : handleKeyDown)}
        onBlur={handleOnBlur}
        onFocus={onFocus}
        error={showError}
        helperText={showError && error ? error : helperText}
        variant={'standard'}
        size={'small'}
        inputProps={{
            maxLength: maxlength || (props.maxRows ? 1000 : 100),
            min: props.minValue,
            max: props.maxValue || (type === 'date' ? '2050-12-31' : undefined),
            readOnly: props.readonly,
            autoComplete: autoComplete,
            pattern: props.pattern?.re,
            title: props.pattern?.message,
            // style: !!clearable ? {
            //     marginRight: '-14px'
            // } : undefined
        }}
        InputProps={(!!clearable || suffixText || prefixText) ? {
            startAdornment: (prefixText) ?
                <InputAdornment position={"start"}>
                    {prefixText}
                </InputAdornment>
                : undefined,
            endAdornment: (!!currentValue || suffixText) ?
                <InputAdornment position="end">
                    {clearable && !!currentValue && <IconButton onClick={() => {
                        if (inputRef.current) {
                            // setValue('');
                            inputRef.current.value = '';
                            handleOnBlur({type: 'blur', target: inputRef.current, bubbles: true});
                        }
                    }}>
                        <Clear/>
                    </IconButton>}
                    {suffixText}
                </InputAdornment>
                : undefined
        } : undefined}
        multiline={type === 'textarea'}
        minRows={props.minRows}
        maxRows={props.maxRows}
        rows={props.rows}
        placeholder={props.placeholder}
        disabled={props.disabled}
        required={props.required}
    >
    </TextField>;

    return props.tooltip ? <Tooltip title={<div>
        <div dangerouslySetInnerHTML={{__html: props.tooltip}}/>
    </div>}>
        {field}
    </Tooltip> : field;
}


export const TextFormField = (props: Props) => {
    const {
        name, onChange, onBlur, formatValue, normalizeValue,
        minValue, maxValue
    } = props;

    const [field, meta, {setValue}] = useField(name);
    const {submitCount} = useFormikContext();
    const [innerValue, setInnerValue] = useState('');
    const timeoutHandle = useRef<any>(null);

    const showError = !!meta.error && ((meta.touched && !!innerValue) || submitCount > 0);

    const formValue = field.value;
    useEffect(() => {
        if (formValue || formValue === 0) {
            setInnerValue(formatValue ? formatValue(formValue as string) : formValue as string);
        } else {
            setInnerValue('');
        }
    }, [formValue, formatValue]);

    const handleOnChange = useCallback((newValue: any) => {
        if (maxValue && parseInt(newValue) > maxValue) {
            newValue = maxValue;
        } else if (minValue !== undefined && parseInt(newValue) < minValue) {
            newValue = minValue;
        }
        setInnerValue(newValue);
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }
        timeoutHandle.current = setTimeout(() => {
            const normalized = normalizeValue ? normalizeValue(newValue) : newValue;
            setValue(normalized);
            if (onChange) {
                onChange(normalized);
            }
            timeoutHandle.current = null;

        }, INPUT_DELAY);
    }, [setValue, onChange, normalizeValue, minValue, maxValue]);

    const handleOnBlur = useCallback((newValue: any) => {
        setInnerValue(newValue);

        const normalized = normalizeValue ? normalizeValue(newValue) : newValue;
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
            setValue(normalized, true);
            if (onChange) {
                onChange(normalized);
            }
            timeoutHandle.current = null;

        } else {
            setValue(normalized, true);
        }
        if (onBlur) {
            onBlur(normalized);
        }
        timeoutHandle.current = null;

    }, [setValue, onChange, onBlur, normalizeValue]);

    const handleOnEnter = useCallback((newValue: any) => {
        setInnerValue(newValue);
        if (timeoutHandle.current) {
            clearTimeout(timeoutHandle.current);
        }

        const normalized = normalizeValue ? normalizeValue(newValue) : newValue;
        setValue(normalized, true);
        if (onChange) {
            onChange(normalized);
        }
        timeoutHandle.current = null;

    }, [setValue, onChange, normalizeValue]);

    return <TextFormFieldPlain {...props}
        currentValue={innerValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onEnter={handleOnEnter}
        showError={showError}
        error={meta.error}
    />;
}
