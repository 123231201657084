import format from 'date-fns/format';
import localeCS from 'date-fns/locale/cs';
import localeEN from 'date-fns/locale/en-US';
import {add, differenceInCalendarDays, lastDayOfMonth, parse, parseISO} from "date-fns";

const dateLocales: { [lng: string]: any } = {
    cs: localeCS,
    en: localeEN,
};

let locale: Locale = dateLocales.en;

export function setDateLocale(lng: string) {
    locale = dateLocales[lng] || dateLocales.en;
}

export function dateToGui(val: Date | string | undefined, asFormat?: string) {
    if (!val
        || val === 'Invalid Date'
        || ((val as Date)?.getFullYear && (val as Date)?.getFullYear() <= 1970)) {
        return undefined;
    }
    const v: Date = typeof val === 'string' ? new Date(val) : val;
    return format(v, asFormat || 'P', {locale}); // 'd.M.yyyy'
}

export function datetimeToGui(val: Date | undefined) {
    if (!val || val.getFullYear() <= 1970) {
        return undefined;
    }
    return format(val, 'P p', {locale}); // 'd.M.yyyy HH:mm'
}

export function timeToGui(val: Date | undefined, asFormat?: string) {
    if (!val) {
        return undefined;
    }
    return format(val, asFormat || 'p', {locale}); // 'HH:mm'
}

export function timeVerboseToGui(val: number) {
    if (!val) {
        return undefined;
    }
    return (val > 60
            ? Math.floor(val / 60) + ' h'
            : '0 h')
        + ((val % 60) > 0
            ? ' ' + (val % 60) + ' min'
            : '')
}

export function guiToDatetime(val: Date | string | undefined) {
    if (typeof val === 'string') {
        return parseISO(String(val));
    }
    return val;
}

export function getDayOfWeekName(dow: number) {
    return locale.localize?.day(dow % 7);
}

export function dateDiff(a: Date | string | undefined, b: Date | string | undefined) {
    if (!a || !b) {
        return undefined;
    }
    const da: Date = typeof a === 'string' ? new Date(a) : a;
    const db: Date = typeof b === 'string' ? new Date(b) : b;
    return differenceInCalendarDays(db, da);
}

export function addDuration(date: Date, duration: Duration) {
    return add(date, duration);
}

export function firstDayOfMonthYmd(date: Date) {
    return format(date, 'yyyy-MM-01');
}

export function lastDayOfMonthYmd(date: Date) {
    return format(lastDayOfMonth(date), 'yyyy-MM-dd');
}

export function addTime(time: string, duration: Duration) {
    return format(add(parse(time, "HH:mm", new Date()), duration), "HH:mm");
}
