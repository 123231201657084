import {ContentLearningEventShowResponseOrdersInner, OrderUpdate} from "generated-api";
import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {isApiResultError} from "../../helpers/api";
import {Formik, FormikErrors} from "formik";
import {Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, LinearProgress, Paper} from "@mui/material";
import Button from "@mui/material/Button";
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {quillDecodeIndent, quillEncodeIndent} from "utils/quill";
import {updateLearningEventOrder} from "store/userGroup";

interface ValuesType extends OrderUpdate {
}

const dialogStyle = {
    "& .MuiDialog-container": {
        // "& .MuiPaper-root": {
        //     width: "100%",
        //     maxWidth: "800px",
        // },
        '& input.Mui-disabled': {
            color: 'var(--color-black-text)',
            'WebkitTextFillColor': 'var(--color-black-text)'
        },
        '& h3': {
            margin: 0,
            padding: 0,
            fontSize: '18px',
        }
    }
}

const formats = [
    'header',
    'code-block', 'bold', 'italic', 'underline',
    'list', 'bullet', 'indent',
    'link'
];

export const EditOrderNoteDialog = ({order, learningEventId, onSuccess, onClose}: {
    order: ContentLearningEventShowResponseOrdersInner,
    learningEventId: number,
    onSuccess: (result: any) => void,
    onClose: () => void
}) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);

    const quillEditor = useRef<any>();

    const initialValues: ValuesType = useMemo(() => {
        return {
            ...order,
            note: order.note ? quillEncodeIndent(order.note) : ''
        }
    }, [order]);

    const handlers = useMemo(() => ({
        undo: () => {
            return quillEditor.current?.history.undo();
        },
        redo: () => {
            return quillEditor.current?.history.redo();
        },
    }), []);

    const modules = useMemo(() => ({
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        },
        toolbar: {
            container: [
                [{'header': 2}, {'header': 3}, {'header': ''}],
                ['code-block', 'bold', 'italic', 'underline'],
                [{'list': 'bullet'}, {'list': 'ordered'}/*, {'indent': '-1'}, {'indent': '+1'}*/],
                ['link'],
                ['undo', 'redo']
            ],
            handlers
        },
        clipboard: {
            matchVisual: false
        }
    }), [handlers]);

    const handleSave = useCallback(async (values: ValuesType) => {
        setIsSaving(true);

        const res = await dispatch(updateLearningEventOrder({
            id: String(order.id),
            learningEventId,
            body: {
                ...order,
                note: values.note && !!values.note.replace(/(<([^>]+)>)/gi, "") ? quillDecodeIndent(values.note) : undefined,
            }
        }));
        if (!isApiResultError(res)) {
            onSuccess(res.payload);
            onClose();
        }
        setIsSaving(false);
    }, [order, learningEventId, dispatch, onSuccess, onClose]);

    const handleValidate = useCallback((values: ValuesType) => {
        let errors = {} as FormikErrors<ValuesType>;
        if (!values.note) {
            // errors.description = t('offer.description.dialog.required')
        }
        return errors;
    }, []);

    useEffect(() => {
        const icons = Quill.import("ui/icons");
        if (icons["undo"]) {
            return;
        }
        icons['header'] = {
            ...icons['header'],
            '3': icons['header']['2'],
            '2': icons['header']['1']
        };
        icons["undo"] = '<svg viewBox="0 0 18 18">' +
            '    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />' +
            '    <path' +
            '      class="ql-stroke"' +
            '      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"' +
            '    />' +
            '  </svg>';
        icons["redo"] = '<svg viewBox="0 0 18 18">' +
            '    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />' +
            '    <path' +
            '      class="ql-stroke"' +
            '      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"' +
            '    />' +
            '  </svg>';

    }, []);

    return (
        <Dialog open={true} onClose={onClose} PaperComponent={Paper} sx={dialogStyle} maxWidth={'md'}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSave}
                validate={handleValidate}
            >
                {({values, handleSubmit, setFieldValue, submitCount}) => {
                    return <form onSubmit={handleSubmit}>
                        <DialogTitle className={'tw-flex tw-w-full tw-justify-between tw-items-center'}>
                            <span>{t('offer.description.dialog.title')}</span>
                            <IconButton onClick={onClose} sx={{color: 'inherit'}}><Close/></IconButton>
                        </DialogTitle>
                        <LinearProgress hidden={!isSaving}/>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sx={{
                                    '& .MuiFormLabel-root': {
                                        color: 'var(--color-black-text)',
                                        fontSize: '14px'
                                    },
                                    '& .ql-editor': {
                                        minHeight: '250px',
                                        maxHeight: 'calc(100vh - 320px)',
                                        overflow: 'auto',
                                        '& p': {
                                            padding: '4px 0'
                                        }
                                    },
                                }}>
                                    <FormLabel>{t('userGroup.registration.note')}</FormLabel>
                                    <ReactQuill theme="snow"
                                        ref={(el) => {
                                            if (el) {
                                                quillEditor.current = el.getEditor();
                                            }
                                        }}
                                        value={values.note}
                                        modules={modules}
                                        formats={formats}
                                        onChange={(v) => setFieldValue('note', v, true)}/>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{padding: '16px'}}>
                            <Button color={'inherit'} onClick={onClose} disabled={isSaving}>
                                {t('form.cancel')}
                            </Button>
                            <Button color={'primary'} variant={'contained'} type={'submit'} disabled={isSaving}>
                                {t('form.save')}
                            </Button>
                        </DialogActions>
                    </form>
                }}
            </Formik>
        </Dialog>
    );
};
