import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useAppDispatch} from '../store';
import {useAppTranslation} from "../services/i18n";
import {cancelUserGroup, deleteUserGroup, fetchUserGroup, fetchUserGroups} from "store/userGroup";
import {isApiResultError} from "../helpers/api";
import {
    CompanySpacesUserGroupsGetKindEnum,
    CompanySpacesUserGroupsGetRequest,
    CompanySpacesUserGroupsGetStatusEnum,
    type ContentLearningEventShowResponse,
    type ContentManagerUserGroupDashboardContentResponseLearningEvent,
    type ContentManagerUserGroupDashboardContentResponseLearningEventDaysInner,
    type ContentManagerUserGroupIndexResponse,
    type ContentManagerUserGroupShowResponse,
    UserBasicInfo,
    UserGroupCreate,
    UserGroupUpdate
} from "../generated-api";
import {Card, CardContent, CardHeader, Grid, LinearProgress, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import {
    colCenter,
    colFilterSimple,
    createCol,
    DataGrid,
    DataGridCol,
    DataGridColFilter,
    DataGridItemAction,
    DataGridMode,
    DataGridState,
    FilterContext,
    ItemsState,
    OrderDirType
} from "components/DataGrid";
import {AddOutlined} from "@mui/icons-material";
import {addMessage} from "store/localApp";
import {TextFormField} from "components/form/TextFormField";
import {ModalProvider, useModal} from "services/modal";
import {PageTabs} from "components/PageTabs";
import {UserGroupDialog} from "components/userGroup/UserGroupDialog";
import {dateToGui, timeVerboseToGui} from "../helpers/date";
import {UserGroupFilterDialog, UserGroupFilterType} from "components/userGroup/UserGroupFilterDialog";
import {useParams} from "react-router-dom";
import {UserGroupRegisteredTab} from "components/userGroup/UserGroupRegisteredTab";
import {UserGroupDaysTab} from "components/userGroup/UserGroupDaysTab";
import {ReactComponent as PlansIcon} from 'icons/plans.svg';
import {getNgService} from "utils/utils";

const createDefaultPrivateUserGroup = (): UserGroupCreate => ({
    kind: '',
    name: '',
    learning_event: {
        location: '',
        coordinator_ids: []
    }
});

interface UserGroupInfo extends ContentManagerUserGroupIndexResponse {
    status: any; // array vs string
    lecturers?: ContentManagerUserGroupDashboardContentResponseLearningEventDaysInner['lecturers'],
    coordinators?: ContentManagerUserGroupDashboardContentResponseLearningEvent['coordinators'];
    unlimited_capacity?: ContentManagerUserGroupDashboardContentResponseLearningEvent['unlimited_capacity'];
}

export interface UserGroupGridFilter extends CompanySpacesUserGroupsGetRequest {
    search?: string,
    name?: string, // TODO
    content?: number, // TODO
    form?: ContentManagerUserGroupDashboardContentResponseLearningEvent['form'], // TODO
}

const defaultState: DataGridState<UserGroupInfo, UserGroupGridFilter> = {
    orderCol: 'name',
    orderDir: OrderDirType.ASC,
    filter: {
        search: '',
        coordinatorIds: [],
        lecturerIds: [],
    },
    pageSize: 20,
    page: 1
};

type ListTabProps = {
    kind?: CompanySpacesUserGroupsGetKindEnum,
    statusActive?: boolean,
    handleEditUserGroup: (userGroup: UserGroupInfo) => void
}

export interface UserGroup extends ContentManagerUserGroupShowResponse {

}

export interface LearningEvent extends ContentLearningEventShowResponse {

}

export interface LearningEventDay extends ContentManagerUserGroupDashboardContentResponseLearningEventDaysInner {

}

const renderUsersShort = (users?: UserBasicInfo[]) => {
    if (!users?.length) {
        return null;
    }
    const first = users[0].full_name
        .split(' ')
        .reduce((prev, curr) => prev + (curr[0] === '(' ? '' : curr[0]), '');
    let title, short;
    if (users.length === 1) {
        short = first;
        title = users[0].full_name;
    } else {
        short = first + ' + ' + (users.length - 1);
        title = users.map(u => <div key={u.id}>{u.full_name}</div>);
    }
    return <Tooltip title={title}><abbr style={{textDecoration: 'underline dotted gray'}}>{short}</abbr></Tooltip>
}

const UserGroupsListTab = ({kind, statusActive, handleEditUserGroup}: ListTabProps) => {
    const t = useAppTranslation();
    const modal = useModal();
    const dispatch = useAppDispatch();

    const [dataGridState, setDataGridState] = useState(defaultState);
    const [itemsState, setItemsState] = useState<ItemsState<UserGroupInfo>>({items: [], isLoading: true});
    const [colFilter, setColFilter] = useState<DataGridColFilter<UserGroupGridFilter, UserGroupFilterType>>();

    const handleFetchUserGroups = useCallback(async () => {
        setItemsState(s => ({...s, isLoading: true}));
        const res = await dispatch(fetchUserGroups({
            // scope
            kind,
            status: statusActive === undefined ? undefined : (statusActive ? ["active"] : ["completed", "cancelled"]),
            // filter
            ...dataGridState.filter,
            term: dataGridState.filter.search,
            // sort
            sort: dataGridState.orderCol ? dataGridState.orderCol + ':' + (dataGridState.orderDir || 'asc') : undefined,
            // paging
            perPage: dataGridState.pageSize,
            page: dataGridState.page
        }));
        if (!isApiResultError(res)) {
            const userGroups: UserGroupInfo[] = res.payload as UserGroupInfo[] || [];
            for (let userGroup of userGroups) {
                // flatten
                userGroup.form = userGroup?.learning_event?.form!;
                userGroup.lecturers = userGroup?.learning_event?.days
                    ?.filter(d => d.lecturers?.length)
                    ?.map(d => d.lecturers as UserBasicInfo[])
                    ?.flatMap(a => a)
                    ?.filter((u, i, self) => u && self.find(o => o.id === u.id) === u);
                userGroup.coordinators = userGroup?.learning_event?.coordinators;
                userGroup.unlimited_capacity = userGroup?.learning_event?.unlimited_capacity;
                userGroup.start_date = userGroup?.learning_event?.days?.[0]?.start_date || userGroup.start_date;
            }
            setItemsState(s => ({
                ...s,
                items: dataGridState.page && dataGridState.page > 1
                    ? [...(s.items || []), ...userGroups]
                        .filter((item, i, items) => items
                            .find(o => o.id === item.id) === item)
                    : userGroups,
                isLoading: false
            }));
        }

    }, [kind, statusActive, dataGridState, dispatch]);

    const handleOpenUserGroup = useCallback(async (userGroup: UserGroupInfo) => {
        if (!userGroup?.id) {
            return;
        }
        window.location.href = '#/planManagement/plans/' + userGroup.id;
    }, []);

    const handleDeleteUserGroup = useCallback(async (userGroup: UserGroupInfo) => {
        if (!userGroup?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.delete.action'),
            message: t('userGroup.delete.message'),
            confirmText: t('userGroup.delete.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(deleteUserGroup({id: '' + userGroup.id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.delete.success')
        }));
        await handleFetchUserGroups();
    }, [handleFetchUserGroups, dispatch, modal, t]);

    const handleCancelUserGroup = useCallback(async (userGroup: UserGroupInfo) => {
        if (!userGroup?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.cancel.action'),
            message: t('userGroup.cancel.message'),
            confirmText: t('userGroup.cancel.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(cancelUserGroup({userGroupId: userGroup.id, body: {}}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.cancel.success')
        }));
        await handleFetchUserGroups();
    }, [handleFetchUserGroups, dispatch, modal, t]);

    const actions: DataGridItemAction<UserGroupInfo>[] = useMemo(() => [
        {
            title: 'userGroup.action.detail',
            // icon: <VisibilityOutlined/>,
            callback: handleOpenUserGroup
        },
        {
            title: 'userGroup.action.edit',
            // icon: <Edit/>,
            callback: handleEditUserGroup,
            isApplicable: (userGroup: UserGroupInfo) => userGroup.id < 0 // TODO
        },
        {
            title: 'userGroup.action.delete',
            color: 'error',
            // icon: <Delete/>,
            callback: handleDeleteUserGroup,
            isApplicable: (userGroup: UserGroupInfo) => userGroup.status === CompanySpacesUserGroupsGetStatusEnum.Active
                && userGroup.kind === 'elearning'
        },
        {
            title: 'userGroup.action.cancel',
            // icon: <Delete/>,
            color: 'error',
            callback: handleCancelUserGroup,
            isApplicable: (userGroup: UserGroupInfo) => userGroup.status === CompanySpacesUserGroupsGetStatusEnum.Active
                && userGroup.kind === 'blended_learning'
        }
    ], [handleEditUserGroup, handleOpenUserGroup, handleDeleteUserGroup, handleCancelUserGroup]);

    const cols = useMemo(() => {
        const simpleFilterContext: FilterContext<UserGroupGridFilter, UserGroupFilterType> = {
            defaultFilter: defaultState.filter,
            currentFilter: dataGridState.filter,
            onOpen: setColFilter,
            onApply: (values) => {
                setDataGridState(s => ({
                    ...s,
                    filter: {
                        ...s.filter,
                        ...values
                    },
                    page: 1
                }))
            }
        }

        const cols: DataGridCol<UserGroupInfo>[] = [
            createCol(t('userGroup.table.name'), 'name', undefined, '20%')
        ];

        if (!kind) {
            cols.push(colFilterSimple(createCol(t('userGroup.table.kind'), 'kind', (v) => t('userGroup.kind.' + v), '15%', 'sm'), simpleFilterContext));
        }

        if (kind === 'blended_learning') {
            cols.push(createCol(t('userGroup.table.blendedContent'), 'content_version_name', (v) => v ? v : '-', '15%', 'xs'));
            cols.push(colFilterSimple(colCenter(createCol(t('userGroup.table.startDate'), 'start_date',
                (v) => dateToGui(v) || '-', '7.5%')), simpleFilterContext, 'startDate'));

            if (statusActive) {
                cols.push(colFilterSimple(createCol(t('userGroup.table.daysHours'), 'days_count',
                    (_, item) => (item.days_count || '-') + ' / ' + (item.time_estimation ? timeVerboseToGui(item.time_estimation) : '-'), '7.5%', 'lg'), simpleFilterContext, 'daysCount'));

                cols.push(colFilterSimple(createCol(t('userGroup.table.form'), 'form',
                    (v) => t('userGroup.form.' + v), '7.5%', 'sm'), simpleFilterContext));

                const lecturers: DataGridCol<UserGroupInfo> = createCol(t('userGroup.table.lecturers'), 'lecturers', renderUsersShort, '7.5%', 'lg');
                lecturers!.orderCol = undefined;
                cols.push(colFilterSimple(lecturers, simpleFilterContext, 'lecturerIds'));

                const coordinators: DataGridCol<UserGroupInfo> = createCol(t('userGroup.table.coordinators'), 'coordinators', renderUsersShort, '7.5%', 'lg');
                coordinators!.orderCol = undefined;
                cols.push(colFilterSimple(coordinators, simpleFilterContext, 'coordinatorIds'));
            }
            cols.push(colFilterSimple(createCol(t('userGroup.table.participants'), 'user_count', (_, item) => (item.user_count || '0')
                + (item.unlimited_capacity || !item.maximum_capacity ? '' : (' / ' + item.maximum_capacity)), '7.5%', 'sm'), simpleFilterContext, 'userCount'));

        } else {
            cols.push(colFilterSimple(createCol(t('userGroup.table.users'), 'user_count', undefined, '7.5%', 'sm'), simpleFilterContext, 'userCount'));
        }

        cols.push(colFilterSimple(createCol(t('userGroup.table.content'), 'content_count', undefined, '7.5%', kind !== 'elearning' ? 'xl' : 'sm'), simpleFilterContext, 'contentCount'));

        if (kind !== 'elearning' && (statusActive === undefined || !statusActive)) {
            cols.push(colFilterSimple(createCol(t('userGroup.table.status'), 'status', (v) => t('userGroup.status.' + v), '7.5%'), simpleFilterContext));
        }

        cols.push(colFilterSimple(createCol(t('userGroup.table.selfAssign'), 'self_assignable',
            (v) => t('common.' + (v ? 'yes' : 'no')), kind === 'blended_learning' ? '7.5%' : '15%', 'xl'), simpleFilterContext, 'selfAssignable'));

        if (kind === 'blended_learning') {
            cols.push(colFilterSimple(createCol(t('userGroup.table.offer'), 'offer_type', (v, item) => {
                const label = t('userGroup.offerType.' + (v || 'none'));
                if ((v === 'public' || v === 'public_and_private') && item.public_offer?.marketplace_url) {
                    return <a href={item.public_offer?.marketplace_url} target='_blank' rel={"noreferrer"}>{label}</a>;
                }
                return label;
            }, '7.5%', 'md'), simpleFilterContext, 'offerType'));
        }

        return cols;
    }, [dataGridState.filter, statusActive, kind, t]);

    const createFilter = useCallback((formProps?: any) => {
        return <Grid container spacing={4} className={'tw-pb-16'}>
            {!!formProps && <Grid item lg={4} md={6} xs={12}>
                <TextFormField
                    name={'search'}
                    label={t('userGroup.filter.search.label')}
                    placeholder={t('userGroup.filter.search.placeholder')}
                    tooltip={t('userGroup.filter.search.hint')}
                />
            </Grid>}
        </Grid>;
    }, [t]);

    useEffect(() => {
        handleFetchUserGroups().then();
    }, [dispatch, handleFetchUserGroups]);

    return <CardContent className={'tw-p-32 tw-pb-0 tw-flex-grow tw-flex tw-flex-col tw-overflow-auto'}>
        <DataGrid
            createFilter={createFilter}
            cols={cols}
            state={dataGridState}
            defaultState={defaultState}
            setState={setDataGridState}
            itemsState={itemsState}
            mode={DataGridMode.SERVER}
            emptyListMessage={t('userGroup.table.emptyList')}
            emptySearchMessage={t('userGroup.table.emptySearch')}
            actions={actions}
            isActionMenu
            rowClick={handleOpenUserGroup}
        />
        {!!colFilter && <UserGroupFilterDialog
            colFilter={colFilter}
            kind={kind}
            onClose={() => {
                setColFilter(undefined);
            }}/>}
    </CardContent>;
};

export const UserGroupsPage = ({tab}: { tab?: string }) => {
    const t = useAppTranslation();
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const {sub} = useParams();

    const userGroupId = sub && !isNaN(parseInt(sub)) ? parseInt(sub) : undefined;
    const currentTab = tab || (sub && !userGroupId ? sub : undefined);

    const [editUserGroup, setEditUserGroup] = useState<UserGroupCreate | UserGroupUpdate>();
    const [userGroup, setUserGroup] = useState<UserGroup>();
    const [counter, setCounter] = useState(0);

    const handleEditUserGroup = useCallback((event: any) => {
        if (event.detail?.userGroup?.id) {
            dispatch(fetchUserGroup({id: String(event.detail.userGroup.id!)}))
                .then(res => setEditUserGroup(res.payload as UserGroup));
        }
    }, [dispatch]);

    const grid = useCallback((props?: Partial<ListTabProps>) => {
        return <UserGroupsListTab key={props?.kind + '-' + props?.statusActive + '@' + counter} {...props} handleEditUserGroup={setEditUserGroup}/>;
    }, [counter]);

    const tabs = useMemo(() => {
        return [
            {
                tab: '',
                label: t('userGroup.tab.all'),
                content: () => grid()
            }, {
                tab: 'elearning',
                label: t('userGroup.tab.eLearning'),
                content: () => grid({kind: 'elearning'})
            }, {
                tab: 'blended',
                label: t('userGroup.tab.blended'),
                content: () => grid({kind: 'blended_learning', statusActive: true})
            }, {
                tab: 'archived',
                label: t('userGroup.tab.archived'),
                content: () => grid({kind: 'blended_learning', statusActive: false})
            },
        ]
    }, [grid, t]);

    // const handleTabChange = useCallback((tab: string) => {
    //     navigate('/planManagement/plans' + (tab ? '/' + tab : ''));
    // }, [navigate]);
    //

    const content = useMemo(() => {
        if (userGroupId) {
            if (!userGroup) {
                return <LinearProgress/>
            }
            // inside tabs
            if (currentTab === 'registered') {
                return <UserGroupRegisteredTab userGroup={userGroup}/>;
            }
            if (currentTab === 'days') {
                return <UserGroupDaysTab userGroup={userGroup}/>;
            }
            return null;
        }

        return <div className={'layout-fill layout-column tw-absolute'}>
            <div className={'kws-content-margin flex tw-h-100'}>
                <Card className={'tw-h-full tw-flex tw-flex-col'}>
                    <CardHeader
                        className={'max-sm:tw-flex-wrap max-sm:tw-gap-12'}
                        title={<>
                            <PlansIcon/>
                            <span>{t('userGroup.title')}</span>
                        </>}
                        action={<Button color={'primary'} variant={'contained'} disabled={false}
                            onClick={() => setEditUserGroup(createDefaultPrivateUserGroup())}
                            className={'tw-w-auto'}>
                            <AddOutlined/>
                            {t('userGroup.action.add')}
                        </Button>}
                    />
                    <PageTabs tabs={tabs} currentTab={undefined} /*onTabChange={handleTabChange}*//>
                </Card>
            </div>
        </div>;

    }, [currentTab, userGroupId, userGroup, tabs, t]);

    useEffect(() => {
        if (!userGroupId) {
            return;
        }
        dispatch(fetchUserGroup({id: String(userGroupId)}))
            .then(res => setUserGroup(res.payload as UserGroup));

    }, [userGroupId, dispatch]);

    useEffect(() => {
        window.addEventListener("reactUserGroupEdit", handleEditUserGroup);
        return () => {
            window.removeEventListener("reactUserGroupEdit", handleEditUserGroup);
        }
    }, [handleEditUserGroup]);

    return <ModalProvider>
        {content}
        {!!editUserGroup &&
            <UserGroupDialog
                id={(editUserGroup as any).id}
                userGroup={editUserGroup}
                onSuccess={(userGroup) => {
                    setCounter(c => c + 1);
                    getNgService("userGroupsManager").addGroupToManager(userGroup); // update / push
                    if (!(editUserGroup as any).id) {
                        // just created
                        window.location.href = '#/planManagement/plans/' + userGroup.id;
                    }
                }}
                onClose={() => {
                    setEditUserGroup(undefined);
                }}/>}
    </ModalProvider>;
};
