import {
    CompanySpacesLearningEventIdGetRequest,
    CompanySpacesLearningEventLearningEventIdDaysIdDeleteRequest,
    CompanySpacesLearningEventLearningEventIdDaysIdPutRequest,
    CompanySpacesLearningEventLearningEventIdDaysPostRequest, CompanySpacesLearningEventLearningEventIdOrdersIdPutRequest,
    CompanySpacesLearningEventLearningEventIdParticipantsIdDeleteRequest,
    CompanySpacesUserGroupsGetRequest,
    CompanySpacesUserGroupsIdDeleteRequest,
    CompanySpacesUserGroupsIdGetRequest,
    CompanySpacesUserGroupsIdPutRequest,
    CompanySpacesUserGroupsPostRequest,
    CompanySpacesUserGroupsUserGroupIdCancelPostRequest,
    DayApi,
    LearningEventApi, OrderApi,
    ParticipantApi,
    UserGroupApi
} from "../generated-api";
import {API_CONFIG} from "app/api-config";
import {createAsyncThunk} from "@reduxjs/toolkit";

const userGroupApi = new UserGroupApi(API_CONFIG);
const learningEventApi = new LearningEventApi(API_CONFIG);
const participantApi = new ParticipantApi(API_CONFIG);
const dayApi = new DayApi(API_CONFIG);
const orderApi = new OrderApi(API_CONFIG);

export const fetchUserGroups = createAsyncThunk('userGroup/list', async (args: CompanySpacesUserGroupsGetRequest) => {
    return await userGroupApi.companySpacesUserGroupsGet(args);
});

export const fetchUserGroup = createAsyncThunk('userGroup/get', async (args: CompanySpacesUserGroupsIdGetRequest) => {
    return await userGroupApi.companySpacesUserGroupsIdGet(args);
});

export const createUserGroup = createAsyncThunk('userGroup/create', async (args: CompanySpacesUserGroupsPostRequest) => {
    return await userGroupApi.companySpacesUserGroupsPost(args);
});

export const updateUserGroup = createAsyncThunk('userGroup/update', async (args: CompanySpacesUserGroupsIdPutRequest) => {
    return await userGroupApi.companySpacesUserGroupsIdPut(args);
});

export const deleteUserGroup = createAsyncThunk('userGroup/update', async (args: CompanySpacesUserGroupsIdDeleteRequest) => {
    return await userGroupApi.companySpacesUserGroupsIdDelete(args);
});

export const cancelUserGroup = createAsyncThunk('userGroup/cancel', async (args: CompanySpacesUserGroupsUserGroupIdCancelPostRequest) => {
    return await userGroupApi.companySpacesUserGroupsUserGroupIdCancelPost(args);
});

// export const fetchUserGroupUsers = createAsyncThunk('userGroup/getUsers', async (args: CompanySpacesUserGroupsIdGetRequest) => {
//     return await userGroupApi.raw(args);
// });
//
export const fetchLearningEvent = createAsyncThunk('learningEvent/get', async (args: CompanySpacesLearningEventIdGetRequest) => {
    return await learningEventApi.companySpacesLearningEventIdGet(args);
});

export const deleteLearningEventParticipant = createAsyncThunk('learningEvent/deleteParticipant', async (args: CompanySpacesLearningEventLearningEventIdParticipantsIdDeleteRequest) => {
    return await participantApi.companySpacesLearningEventLearningEventIdParticipantsIdDelete(args);
});

export const createLearningEventDay = createAsyncThunk('learningEventDay/create', async (args: CompanySpacesLearningEventLearningEventIdDaysPostRequest) => {
    return await dayApi.companySpacesLearningEventLearningEventIdDaysPost(args);
});

export const updateLearningEventDay = createAsyncThunk('learningEventDay/update', async (args: CompanySpacesLearningEventLearningEventIdDaysIdPutRequest) => {
    return await dayApi.companySpacesLearningEventLearningEventIdDaysIdPut(args);
});

export const deleteLearningEventDay = createAsyncThunk('learningEventDay/update', async (args: CompanySpacesLearningEventLearningEventIdDaysIdDeleteRequest) => {
    return await dayApi.companySpacesLearningEventLearningEventIdDaysIdDelete(args);
});

export const updateLearningEventOrder = createAsyncThunk('learningEvent/updateOrder', async (args: CompanySpacesLearningEventLearningEventIdOrdersIdPutRequest) => {
    return await orderApi.companySpacesLearningEventLearningEventIdOrdersIdPut(args);
});
