import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useNavigate,
  useParams,
  useLocation,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { selectContentViewer } from 'store/selectors';
import {useAppDispatch, withIgnoreErrorCodes} from 'store';
import {
  fetchLesson,
  fetchCourseElements,
  postStepParticipation,
  updateStepParticipation,
} from 'store/contentViewer';
import { ContentViewerNav } from './ContentViewerNav/ContentViewerNav';
import { ContentViewerSidebar } from './ContentViewerSidebar/ContentViewerSidebar';
import { TestViewer } from 'components/test/TestViewer';
import { CourseIntro } from './CourseIntro';
import { PdfViewer } from './PdfViewer';
import { VideoViewer } from './VideoViewer';
import { AudioViewer } from './AudioViewer';
import { IframeViewer } from './IframeViewer';
import IconButton from '@mui/material/IconButton';
import { Certificate } from './Certificate';
import { updateLesson } from 'store/contentViewer';
import { ReactComponent as ChevronLeft } from 'icons/chevron-left.svg';
import { ContentViewerModal } from './ContentViewerModal';
import {getLocale, useAppTranslation} from 'services/i18n';
import { ReactComponent as List } from 'icons/list.svg';
import { useMediaQuery } from '@mui/material';
import { Video } from 'components/video/Video';
import { Link } from 'components/clickable/Link';
import { Location } from 'history';
import { LessonIntro } from './LessonIntro';

interface LocationProps {
  currentLocation: Location;
  nextLocation: Location;
}

export const ContentViewerPage: React.FC = () => {
  const { courseId, lessonId, stepId } = useParams();
  const [showTestNotCompletedModal, setShowTestNotCompletedModal] = useState(false);
  const [showCourseIncompleteModal, setShowCourseIncompleteModal] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const contentViewerState = useSelector(selectContentViewer);
  const [contentLoading, setContentLoading] = useState(true);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const t = useAppTranslation();
  const isMobile = useMediaQuery('(max-width: 639px)');
  const [showSidebar, setShowSidebar] = useState(isMobile ? false : true);
  const handleCloseSidebar = () => setShowSidebar(false);

  const course = contentViewerState?.course;
  const completedAt = course?.completed_at ? new Date(course.completed_at) : undefined;
  const confirmedAt = useMemo(() => {
    return course?.confirmed_at ? new Date(course.confirmed_at) : undefined;
  }, [course?.confirmed_at]);
  const lessons = course?.content_version?.lessons;
  const currentLesson = useMemo(() => lessonId ? lessons?.find((lesson) => lesson.id === +lessonId) : undefined,
      [lessonId, lessons]);
  const currentStep = useMemo(() => stepId
    ? currentLesson?.steps?.find((step) => step.id === +stepId)
    : undefined, [stepId, currentLesson?.steps]);
  const currentStepIsFinished = currentStep?.step_participation?.state === 'completed';
  const meta = course?.content_version?.meta;
  const contentType = course?.content_version?.content_type;
  const progressPercent = course?.percent_done;
  const lessonIdInProgress = course?.current_lesson?.id;
  const stepIdInProgress = course?.current_step?.id;
  const isCourseCompleted = contentViewerState?.course?.state === 'completed';
  const isCourseAssigned = contentViewerState?.course?.state === 'assigned';
  const isPreview = contentViewerState?.course?.is_preview;
  const currentContent = contentViewerState?.currentContent;

  const mainContent =
    currentContent && currentContent?.length > 1
      ? currentContent.find((c) => c.element_type !== 'audio' && c.element_type !== 'video')
      : currentContent?.[0];

  const mainContentType = mainContent?.element_type;
  const mainContentUrl = mainContent?.files?.[0]?.download_url;
  const testDefinition = mainContent?.test_definition;

  const sideAudio =
    currentContent && currentContent?.length > 1
      ? currentContent.find((c) => c.element_type === 'audio')
      : undefined;
  const sideAudioUrl = sideAudio?.files?.[0]?.download_url;

  const sideVideo =
    currentContent && currentContent?.length > 1
      ? currentContent.find((c) => c.element_type === 'video')
      : undefined;
  const sideVideoUrl = sideVideo?.files?.[0]?.download_url;

  const iframeHTML = mainContent?.iframe_html;
  const testAttempts = contentViewerState?.testAttempts;

  const activeTestAttempt =
    testAttempts &&
    testAttempts.find((testAttempt) => {
      return testAttempt.state === 'in_progress';
    });

  const allCourseSteps = useMemo(
    () =>
      lessons
        ?.map((lesson) =>
          lesson.steps?.flatMap((step, ix) => {
            if (ix === 0) {
              return [
                {
                  lessonId: lesson.id,
                  lessonPosition: lesson.position,
                  lessonParticipation: lesson.lesson_participation,
                  stepId: undefined,
                  stepPosition: undefined,
                  state: undefined,
                  name: undefined,
                  type: undefined,
                  stepParticipation: undefined,
                },
                {
                  lessonId: lesson.id,
                  lessonPosition: lesson.position,
                  stepId: step.id,
                  stepPosition: step.position,
                  state: step.step_participation?.state,
                  name: step.name,
                  type: step.element_types[0],
                  stepParticipation: step.step_participation,
                },
              ];
            }
            return {
              lessonId: lesson.id,
              lessonPosition: lesson.position,
              stepId: step.id,
              stepPosition: step.position,
              state: step.step_participation?.state,
              name: step.name,
              type: step.element_types[0],
              stepParticipation: step.step_participation,
            };
          })
        )
        .flat(),
    [lessons]
  );

  const startCourse = useCallback(async () => {
    if (courseId)
      await dispatch(
        updateLesson({
          id: courseId,
          body: {
            state: 'in_progress',
          },
        })
      );
  }, [courseId, dispatch]);

  const handleCompleteParticipation = useCallback(
    async (stepId: string, courseId: string) => {
      // Logic to start course (change to "in_progress" state), when user only completes steps from sidebar staying on CourseIntro screen
      isCourseAssigned && startCourse();

      const step = allCourseSteps?.find((step) => step?.stepId === +stepId);
      const isInProgress = step?.state === 'in_progress';
      const isCompleted = step?.state === 'completed';
      if (isCompleted) return;

      const requestBody = {
        step_id: +stepId,
        state: 'completed',
        content_participation_id: +courseId,
      };

      isInProgress
        ? await dispatch(updateStepParticipation({ body: requestBody }))
        : await dispatch(postStepParticipation({ body: requestBody }));
      await dispatch(fetchLesson(courseId));
    },
    [dispatch, allCourseSteps, isCourseAssigned, startCourse]
  );

  const currentStepIndex = stepId
    ? allCourseSteps?.findIndex((step) => +stepId === step?.stepId)
    : lessonId
    ? allCourseSteps?.findIndex((step) => +lessonId === step?.lessonId)
    : undefined;

  const isFirstStep = currentStepIndex === 0;
  const isLastStep =
    currentStepIndex !== undefined && currentStepIndex + 1 === allCourseSteps?.length;

  const notCompletedSteps = useMemo(() => {
    return allCourseSteps?.filter((step, index) => {
      if (index < allCourseSteps.length - 1) {
        return step?.state !== 'completed' && step?.stepId !== undefined;
      }
      return step?.type === 'test' && step?.state !== 'completed' && step?.stepId !== undefined;
    });
  }, [allCourseSteps]);

  const handleNavigateToNextStep = useCallback(async () => {
    if (contentLoading) return;
    if (allCourseSteps === undefined || currentStepIndex === undefined || courseId === undefined) {
      console.error('Missing course ID or lesson ID');
      return;
    }

    if (mainContentType !== 'test' || (mainContentType === 'test' && currentStepIsFinished)) {
      stepId && (await handleCompleteParticipation(stepId, courseId));
    }

    if (isLastStep && notCompletedSteps && notCompletedSteps?.length > 0) {
      setShowCourseIncompleteModal(true);
      return;
    }

    if (isLastStep && notCompletedSteps && notCompletedSteps?.length === 0 && courseId) {
      navigate(`/learn/${courseId}/certificate`);
      return;
    }
    // logic for skipping completed steps
    let nextStepIndexAddition = 1;
    const getNextStep: any = () => {
      const nextStep = allCourseSteps[currentStepIndex + nextStepIndexAddition];
      if (
        (nextStep?.state === 'completed' && nextStep?.stepId !== allCourseSteps.at(-1)?.stepId) ||
        (!nextStep?.stepId && nextStep?.lessonParticipation?.state === 'completed')
      ) {
        nextStepIndexAddition++;
        return getNextStep();
      }
      return nextStep;
    };

    const nextStep = getNextStep();
    const nextStepUrl = `/learn/${courseId}/lesson/${nextStep?.lessonId}${
      nextStep.stepId ? `/step/${nextStep?.stepId}` : ''
    }`;

    if (stepId === undefined) {
      return navigate(nextStepUrl);
    }

    if (isCourseCompleted && confirmedAt) {
      navigate(`/learn/${courseId}`);
      return;
    }

    return navigate(nextStepUrl);
  }, [
    allCourseSteps,
    currentStepIndex,
    courseId,
    navigate,
    handleCompleteParticipation,
    stepId,
    isLastStep,
    notCompletedSteps,
    currentStepIsFinished,
    mainContentType,
    isCourseCompleted,
    confirmedAt,
    contentLoading,
  ]);

  const handleNavigateToPreviousStep = useCallback(() => {
    if (currentStepIndex === undefined || allCourseSteps === undefined) {
      console.error('Missing step ID or lesson ID');
      return;
    }
    if (isFirstStep) return;

    const previousStep = allCourseSteps[currentStepIndex - 1];
    const previousStepUrl = `/learn/${courseId}/lesson/${previousStep?.lessonId}${
      previousStep?.stepId ? `/step/${previousStep?.stepId}` : ''
    }`;

    return navigate(previousStepUrl);
  }, [allCourseSteps, currentStepIndex, courseId, navigate, isFirstStep]);

  const handlePreviewBack = useCallback(() => {
    if (window.history?.length > 2) {
      window.history.back();
    } else {
      handleNavigateToPreviousStep();
    }
    setShowPreviewModal(false);
  }, [handleNavigateToPreviousStep]);

  const handlePreviewBuy= useCallback(() => {
    if (course?.marketplace_offer_url) {
      window.location.href = course.marketplace_offer_url;
      return;
    }

    // marketplaceCourseRedirector.factory.js
    window.location.href = 'https://'
        + window.location.host.split('.').slice(1).join('.')
        + (getLocale() === 'en' ? '/en/course/' : '/kurz/')
        + course?.marketplace_offer_slug;

  }, [course?.marketplace_offer_url, course?.marketplace_offer_slug]);

  const [courseLoading, setCourseLoading] = useState(true);

  useEffect(() => {
    if (!courseId) return;

    const fetchCourse = async () => {
      await dispatch(fetchLesson(courseId));
      setContentLoading(false);
      setCourseLoading(false);
    };
    fetchCourse();
  }, [dispatch, courseId]);

  useEffect(() => {
    if (!stepId || !courseId) return;

    const fetchContent = async () => {
      setContentLoading(true);

      const initOverrides = course?.is_marketplace_preview && !!course?.marketplace_offer_slug
          ? withIgnoreErrorCodes({
            '403': () => {
              setShowPreviewModal(true);
            }
          })
          : undefined;

      await dispatch(
        fetchCourseElements({
          contentParticipationId: +courseId,
          stepId: +stepId,
          initOverrides
        })
      );
      setContentLoading(false);
    };
    fetchContent();
  }, [course?.is_marketplace_preview, course?.marketplace_offer_slug, course?.marketplace_offer_url, dispatch, stepId, courseId]);

  const lastStepId = useRef<number>();

  useEffect(() => {
    if (!currentStep) return;
    const stepParticipation = currentStep.step_participation;

    if (!stepId || !courseId) return;
    if (lastStepId.current !== undefined && lastStepId.current === stepParticipation?.step_id)
      return;

    lastStepId.current = +stepId;
    if (stepParticipation !== undefined && stepParticipation.state !== 'in_progress') return;

    const handleStartStepParticipation = async () => {
      const requestBody = {
        step_id: +stepId,
        state: 'in_progress',
        content_participation_id: +courseId,
      };
      const res = stepParticipation?.state === 'in_progress'
        ? await dispatch(updateStepParticipation({ body: requestBody, initOverrides: withIgnoreErrorCodes({'403': null})}))
        : await dispatch(postStepParticipation({ body: requestBody, initOverrides: withIgnoreErrorCodes({'403': null})}));
      if ('error' in res) {
        return; // ignore 403
      }
      await dispatch(fetchLesson(courseId));
    };

    handleStartStepParticipation().then();
  }, [stepId, currentStep?.step_participation?.state, currentStep?.step_participation?.step_id, dispatch, courseId]);

  const isCourseIntro = !stepId && !lessonId && meta && lessons && !!lessons.length;
  const isLessonIntro = !stepId && lessonId && lessons && !!lessons.length;
  const isTest = stepId && mainContentType === 'test' && testDefinition;
  const isVideo = stepId && mainContentType === 'video' && mainContentUrl;
  const isAudio = stepId && mainContentType === 'audio' && mainContentUrl;
  const isIFrame = stepId && mainContentType === 'iframe';
  const isPdf =
    stepId &&
    mainContentUrl &&
    (mainContentType === 'presentation' || mainContentType === 'document');
  const isCertificate = location.pathname.endsWith(`learn/${courseId}/certificate`);
  const isSideVideo = !!(sideVideoUrl && (isIFrame || isPdf || isVideo || isAudio));

  const NotCompletedSteps = notCompletedSteps?.map((step) => {
    return (
      <Link
        to={`/learn/${courseId}/lesson/${step?.lessonId}/step/${step?.stepId}`}
        onClick={() => setShowCourseIncompleteModal(false)}
        kind="bare"
        className="tw-no-underline"
      >
        <div
          key={step?.stepId}
          className="tw-rounded-xl tw-bg-lightRed tw-px-12 tw-py-6 tw-text-12 tw-text-darkerRed"
        >
          {step?.lessonPosition}.{step?.stepPosition} {step?.name}
        </div>
      </Link>
    );
  });

  // logic for sending course with "in_progress" state if user is browsing course
  useEffect(() => {
    if (isCourseIntro || !courseId || !isCourseAssigned) return;
    startCourse();
  }, [courseId, dispatch, isCourseIntro, isCourseAssigned, startCourse]);

  useEffect(() => {
    if (progressPercent !== 100 || isCourseCompleted || !courseId) return;
    const finishCourse = async () => {
      if (courseId)
        await dispatch(
          updateLesson({
            id: courseId,
            body: {
              state: 'completed',
            },
          })
        );
    };
    finishCourse();
  }, [courseId, dispatch, progressPercent, isCourseCompleted]);

  const blockNavigation = ({ currentLocation, nextLocation }: LocationProps) => {
    const nextStepId = nextLocation.pathname.split('/').pop();
    if (mainContentType === 'test' && stepId && stepId !== nextStepId && activeTestAttempt) {
      setShowTestNotCompletedModal(true);
      return true;
    }
    // hide sidebar after tap on link on mobile
    isMobile && setShowSidebar(false);
    return false;
  };

  useBlocker(blockNavigation);

  return (
    <div
      className="h-screen tw-relative tw-flex tw-w-screen tw-bg-lightYellow tw-font-roboto"
      id="react-app"
    >
      {isPreview && (
        <div className="tw-absolute tw-z-10 tw-w-300 -tw-translate-x-80 tw-translate-y-60 -tw-rotate-45 tw-bg-[#cc3010] tw-py-10 tw-px-0 tw-text-center tw-text-24 tw-font-bold tw-uppercase tw-text-[#FFFFFFDD] tw-opacity-25">
          {t(`contentViewer.preview`)}
        </div>
      )}
      <div className="tw-flex tw-w-full tw-flex-col tw-overflow-hidden">
        <div className="tw-flex tw-w-full">
          {lessons && (
            <ContentViewerNav
              contentMeta={meta}
              currentStepIsFinished={currentStepIsFinished}
              progress={progressPercent}
              lessons={lessons}
              mainContentType={mainContentType}
              setShowTestNotCompletedModal={setShowTestNotCompletedModal}
              isActiveTestAttempt={activeTestAttempt !== undefined}
              currentStepName={currentStep?.name}
              currentStepPosition={currentStep?.position}
            />
          )}
          {!showSidebar && (
            <IconButton
              className="tw-h-48 tw-w-48 tw-rounded-none tw-border-l tw-border-white/20 tw-bg-darkestGray"
              onClick={() => setShowSidebar(true)}
            >
              <ChevronLeft className="tw-hidden sm:tw-inline" />
              <List className="tw-inline sm:tw-hidden" />
            </IconButton>
          )}
        </div>

        <ContentViewerModal
          isFullScreen={true}
          title={t('contentViewer.missingStepsModal.title')}
          explanation={t('contentViewer.missingStepsModal.explanation')}
          missingItemsInfo={t('contentViewer.missingStepsModal.missingSteps')}
          enterKeyText={t('contentViewer.missingStepsModal.action')}
          missingItems={NotCompletedSteps}
          onEnterKeyPress={() => setShowCourseIncompleteModal(false)}
          showModal={showCourseIncompleteModal}
          setShowModal={setShowCourseIncompleteModal}
        />

        <ContentViewerModal
            isFullScreen={true}
            title={t('contentViewer.previewModal.title')}
            explanation={t('contentViewer.previewModal.explanation')}
            spaceKeyText={t('contentViewer.previewModal.spaceKeyText')}
            onSpaceKeyPress={handlePreviewBack}
            enterKeyText={t('contentViewer.previewModal.action')}
            enterKeyClass={'tw-bg-green tw-border-none'}
            onEnterKeyPress={handlePreviewBuy}
            showModal={showPreviewModal}
            setShowModal={handlePreviewBack}
            modalWidth={420}
        />

        <main className="tw-relative tw-grow tw-bg-background">
          {isLessonIntro && currentLesson && (
            <LessonIntro
              contentLoading={courseLoading}
              lesson={currentLesson}
              isFirstStep={isFirstStep}
              onNavigateToNextStep={handleNavigateToNextStep}
              onNavigateToPreviousStep={handleNavigateToPreviousStep}
            />
          )}
          {isCourseIntro && !isCertificate && (
            <CourseIntro
              meta={meta}
              contentType={contentType}
              progress={progressPercent}
              completedAt={completedAt}
              confirmedAt={confirmedAt}
              lessons={lessons}
              currentLessonId={lessonIdInProgress}
              currentStepId={stepIdInProgress}
              contentLoading={courseLoading}
              courseState={course.state}
            />
          )}
          {isIFrame && (
            <IframeViewer
              iframeHTML={iframeHTML}
              onNavigateToNextStep={handleNavigateToNextStep}
              onNavigateToPreviousStep={handleNavigateToPreviousStep}
              isLastStep={isLastStep}
              contentLoading={contentLoading}
              showCourseIncompleteModal={showCourseIncompleteModal}
            />
          )}
          {isTest && (
            <TestViewer
              contentParticipationId={Number(courseId)}
              testDefinition={testDefinition}
              stepId={stepId}
              allCourseSteps={allCourseSteps}
              currentLesson={currentLesson}
              handleNavigateToNextStep={handleNavigateToNextStep}
              handleNavigateToPreviousStep={handleNavigateToPreviousStep}
              activeTestAttempt={activeTestAttempt}
              showTestNotCompletedModal={showTestNotCompletedModal}
              setShowTestNotCompletedModal={setShowTestNotCompletedModal}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              contentLoading={contentLoading}
              showCourseIncompleteModal={showCourseIncompleteModal}
              currentStepIsFinished={currentStepIsFinished}
            />
          )}
          {isPdf && (
            <PdfViewer
              file={mainContentUrl}
              audioFile={sideAudioUrl}
              onNavigateToNextStep={handleNavigateToNextStep}
              onNavigateToPreviousStep={handleNavigateToPreviousStep}
              currentStepIsFinished={currentStepIsFinished}
              isFirstStep={isFirstStep}
              type={mainContentType}
              isLastStep={isLastStep}
              showCourseIncompleteModal={showCourseIncompleteModal}
              contentLoading={contentLoading}
            />
          )}
          {isVideo && (
            <VideoViewer
              file={mainContentUrl}
              onNavigateToNextStep={handleNavigateToNextStep}
              onNavigateToPreviousStep={handleNavigateToPreviousStep}
              isLastStep={isLastStep}
              isFirstStep={isFirstStep}
              contentLoading={contentLoading}
              showCourseIncompleteModal={showCourseIncompleteModal}
            />
          )}
          {isAudio && (
            <AudioViewer
              file={mainContentUrl}
              onNavigateToNextStep={handleNavigateToNextStep}
              onNavigateToPreviousStep={handleNavigateToPreviousStep}
              isFirstStep={isFirstStep}
              isLastStep={isLastStep}
              contentLoading={contentLoading}
              showCourseIncompleteModal={showCourseIncompleteModal}
            />
          )}
          {isCertificate && <Certificate />}
        </main>
      </div>
      {course && showSidebar && (
        <ContentViewerSidebar
          loading={courseLoading}
          onRegisterParticipation={handleCompleteParticipation}
          course={course}
          onClose={handleCloseSidebar}
          isSideVideo={isSideVideo}
        />
      )}
      {isSideVideo && (
        <Video
          file={sideVideoUrl}
          key={sideVideoUrl}
          isBackgroundFaded={showSidebar}
          className={clsx(
            showSidebar ? 'tw-bottom-0 tw-hidden sm:tw-block' : 'tw-bottom-48 lg:tw-bottom-120'
          )}
        />
      )}
    </div>
  );
};
